.content {
  display: flex;
  flex-direction: column;

  @media (max-width: 1359px) {
    padding: 8px;
    height: fit-content;
    min-height: calc(100vh - 82px - 26px); // height - header - back arrow
  }

  overflow: visible;
  @media (min-width: 1360px) {
    overflow-y: auto;
  }
}

.back {
  height: 100%;
  padding-right: 10px;
  background: transparent;
}

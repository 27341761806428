@import "../../scss/variables.scss";

.dialog {
  position: relative;
  max-width: 600px;

  .loading {
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    
    img {
      margin: 0;
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $main-color;
  }
}

.piiTable {
  width: 100%;

  th {
    border-bottom: 1px solid $light-blue-grey;
  }

  td {
    padding: 10px 0;
    min-width: 135px;
  }

  td:last-of-type,
  th:last-of-type {
    text-align: right;
  }
}

.document {
  border-bottom: 1px solid $light-blue-grey;
  font-size: 16px;

  .name {
    margin: 0;
  }

  // Overriding its-react-ui padding
  .dropdown > div:last-of-type {
    padding: 0;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  > .loading + button,
  > button + button {
    margin-left: 10px;
  }
}

.error {
  color: $warning;
}

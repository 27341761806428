@import '../../scss/variables.scss';

.extension {
    align-items: center;
    color: $black-text-color;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 0 0.1rem;
    text-transform: uppercase;

    &.pdf {
        background-color: #f5866c;
    }
    &.doc, &.docx, &.txt {
        background-color: #4ec3e0;
    }
    &.xls {
        background-color: #73c6a1;
    }
    &.jpg, &.png, &.gif {
        background-color: #b884cb;
    }
}

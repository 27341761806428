// Colors
$light-blue-grey: #d9e3ec;
$marine-blue: #003c71;
$white:#ffffff;
$light-green: #1ee5a0;
$light-blue: #3b659c;
$teal-blue: #2dc2e0;
$dark-blue: #769ab8;
$link-blue: #1a95e7;
$light-grey: #89a3c3;
$grey: #808080;
$grey-box: #e8eef3;
$button-primary: $light-blue;
$button-secondary: #6189af;
$page-background: $light-blue-grey;
$main-color: $marine-blue;
$text-light: $white;
$container: $white;
$black-shadow: rgba(0,0,0,0.1);
$vessel-color: #7f9db8;
$date-color: #4887b1;
$black-text-color: #001a32;
$ddl-item-hover: rgba(30, 229, 160, 0.34);
$ddl-shadow: rgba(0, 60, 113, 0.5);
$tooltip-shadow: rgba(0, 60, 113, 0.4);
$radiobutton-color: rgba(72, 135, 177, 0.6);
$popup-shadow: rgba(0, 26, 50, 0.8);
$warning: rgb(239, 68, 68);

/* Font sizes */
$font-size-default: 1rem; // 14px
$font-size-small: 0.86rem; // 12px
$font-size-heading-small: 1.14rem; // 16px
$font-size-heading: 1.28rem; // 18px;
$font-size-heading-large: 1.57rem; // 22px

/* Margin & padding sizes */
$mp-xxxs: 0.28rem; // 4px
$mp-xxs: 0.42rem; // 6px
$mp-xs: 0.57rem; // 8px
$mp-s: 0.71rem; // 10px
$mp-sm: 0.86rem; // 12px
$mp-m: 1rem; // 14px
$mp-ml: 1.14rem; // 16px
$mp-l: 1.28rem; // 18px
$mp-xl: 1.42rem; // 20px
$mp-xxl: 1.57rem; // 22px
$mp-xxxl: 2rem; // 28px

:export {
    white: $white;
    lightgreen: $light-green;
    maincolor: $marine-blue;
    lightgrey: $light-grey;
    background: $light-blue-grey;
    greybox: $grey-box;
}

@import "../../../scss/variables";

.loadingMessage {
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    margin-right: 8px;
    color: $marine-blue;
  }

  p {
    margin: 0;
  }
}

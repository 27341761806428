@import "../../../../scss/variables";
@import "../../../../scss/shared.scss";

.sender {
  @include ellipsis;
  font-weight: bold;
}

.recipient {
  @include ellipsis;
  font-weight: bold;
}

.extraRecipients {
  font-weight: bold;
  white-space: nowrap;
}

.subject {
  @include ellipsis;
  font-size: 12px;
  color: $black-text-color;
  margin-left: 0.5rem;
  align-self: center;
}

@import "../../scss/variables.scss";

.root {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}

.error {
  color: $warning;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span + button,
  button + button {
    margin-left: 10px;
  }
}

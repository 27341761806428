@import "../../scss/variables";

.wrap {
  font-weight: bold;

  span {
    font-weight: normal;
    margin-right: 10px;
  }
}

@media (max-width: 1023px) {
  .wrap > span { display: none }
}

@import "../../scss/variables.scss";

.tag {
  align-items: center;
  border-radius: 0.28rem;
  border: 1px solid $button-primary;
  display: inline-block;
  min-height: 1.57rem;
  padding: 0 0.36rem;
  user-select: none;
  white-space: nowrap;
  width: min-content;

  > svg {
    margin: 0 0.2rem;
  }

  &.fill {
    background-color: $button-primary;
    color: $text-light;
  }

  &.stroke {
    background-color: transparent;
    color: $button-primary;
  }

  &.withHover {
    &:hover {
      background-color: $light-blue-grey;
    }
  }

  &.icon {
    background-color: $button-primary;
    padding: 0;
    display: flex;
    align-self: center;
  }
}

@import "../../scss/variables";

.clearButton {
  background-color: $white;
  padding: 0;
  width: 48px;
  border-radius: 10px 0px 0px 10px;

  &.roundLeft {
    border-radius: 0;
  }

  &.hidden {
    cursor: default;

    svg {
      visibility: hidden;
    }
  }

  @media (max-width: 576px) {
    &.roundLeft {
      border-radius: 10px 0px 0px 10px;
    }
  }
}

.searchInput {
  border: none;
  border-radius: 0;
  font-size: 18px;
  flex: 1;
  color: $black-text-color;
  padding: 10px 8px;

  &::-ms-clear {
    display: none;
  }
}

.searchButton {
  background-color: $light-green;
  border-radius: 0px 10px 10px 0px;
  padding: 0;
  width: 48px;
  text-align: center;
}

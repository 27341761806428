@import "../../scss/variables.scss";

.searchContainer {
  display: flex;
  justify-content: center;
}

.helpText {
  width: 70vw;
  max-width: 1360px;
  margin: 0 auto;


  @media (max-width: 1023px) {
    margin-left: 16px;
    margin-right: 16px;
  }  
}

@import "../../../scss/variables";
@import "../../../scss/shared.scss";

.header { margin: 20px 0px 20px 0px }

.documentsWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 500px;
  overflow: auto;
  max-width: 50vw;
  
  @media (max-width: 1023px) {
    max-width: none;
  }
}

.contextTable {
  width: 100%;
  margin-top: 2px;
  background-color: $white;
  border: 1px solid #F5F5F5;

  th {
    font-weight: bold;
    border-top: 2px solid black;
  }
  
  th,td {
    padding: 2px 6px;
    vertical-align: top;
    background-color: $white;
    border-bottom: 1px solid #F5F5F5;
  }

  tr {
    display: grid;
    grid-template-columns: 30px repeat(auto-fit, minmax(100px, 1fr));
  }
}

.closeButton {
  margin-top: 43px;
  width: 100%;
  border-radius: 4px;
  height: 32px;
  font-weight: bold;
  color: $white;
  background-color: $marine-blue;
}

.buttons {
  margin-top: 43px;
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 4px;
    height: 32px;
    font-weight: bold;
    &:disabled {
      background-color: gray;
      &:hover {
        cursor: initial;
      }
    }
  }

  > :first-child {
    margin-right: 16px;
    width: 80px;
    border: 1px solid $marine-blue;
    background-color: $white;
  }

  > :last-child {
    width: 130px;
    color: $white;
    background-color: #880D18;
  }
}

.documentInformation {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: end;
  & > span {
    justify-self: end;
  }
}

.row {
  svg {
    cursor: pointer;
    justify-self: center;
    align-self: center;
    height: 18px;
    width: 18px;
  }
}

.selectionActions {
  display: flex;

  svg {
    width: 17px;
    height: 17px;
    fill: $marine-blue;
  }
}



@import "../../scss/variables";
@import "../../scss/shared.scss";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .scrollable {
    display:flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-width: 292px;
  }

  .infoText {
    font-size: 14px;
    margin: 0 25px 0 16px 0;
  }

  .helpText {
    font-size: 18px;
    margin-top: 50px;
    margin: 0 auto;

  }

  ul {
    margin: 0;
    padding: 0;
  }

  // Insurance context info modal window
  [class^=Modal-module__modal] {
    max-width: 400px;
    overflow: auto;
  }

  [class^=Modal-module__closeButton] {
    background: none;
   }

  [class^=BackButton-module__backButton] > button {
    background-color: transparent;
  }

  [class^=ContextList-module],
  [class^=ContextGroupList-module] {
    width: 18.95rem;
  }

  [class^=Contexts-module__container],
  [class^=SearchResult-module__searchResult] {
    padding: 0;
  }

  [class^=CoverInfo-module__notes] {
    overflow: auto;
  }
}

@import "../../../scss/variables.scss";

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 124px 0;
  outline: 2px dashed $light-grey;
  cursor: pointer;
  color: $main-color;
  font-size: 20px;
  margin: 0 30px 5px 30px;

  > svg {
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }

  &:focus,
  &.isDragActive {
    outline-color: $main-color;
  }

  &.invalid {
    outline-color: $warning;
  }

  @media (max-width: 1023px) {
    padding: 0;
  }
}

.title,
.header {
  margin-top: 20px;
}

.header {
  // Reflect the removed margin from h2
  margin-bottom: 0.5rem;

  .title {
    margin-bottom: 0;
  }

  p {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.files {
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}

.selectedFile {
  display: flex;
  align-items: center;

  span + button {
    margin-left: 5px;
  }
}

.contextStep {
  .error {
    justify-content: center;
    margin-top: 10px;
  }
}

.tags {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  > span {
    margin-top: 0.36rem;
    margin-right: 0.36rem;
  }

  > span:last-child {
    margin-right: 0;
  }

  > span:only-child {
    margin: 0;
  }
}

.tag {
  display: flex;
  align-items: center;
}

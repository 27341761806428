@import "../../scss/variables";

.case {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $main-color;
  background: $container;

  &:hover {
    text-decoration: none;
  }

  .content {
    width: 100%;
  }

  .caseIcon {
    flex: 0 0 30px;

    svg {
      fill: $button-secondary;
    }
  }

  .caseDescription {
    color: $main-color;
  }
}

.header {
  color: $main-color;
  text-align: left;
  font-size: 1.5rem;

  @media (max-width: 1023px) {
    margin: 0 8px 8px 8px;
  }
}

.descriptionList {
  position: relative;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  margin: 0;

  &:not(:only-child)::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 1px;
    background: $light-grey;
  }

  dt {
    color: gray;
  }

  dd {
    color: $main-color;
    font-weight: bold;
  }
}

.helpText {
  width: 70vw;
  max-width: 1360px;
  margin: 0 auto;


   @media (max-width: 1023px) {
    margin-left: 16px;
    margin-right: 16px;
  } 
}

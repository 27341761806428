@import "../../scss/variables";

.shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $popup-shadow;
  z-index: 3;
}

.modal {
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 4px;
  padding: 24px;
  background-color: $white;
  color: $marine-blue;

  &.fixedWidth {
    width: 35rem;
    height: auto;
  }

  @media (max-width: 1023px) {
    flex: 1;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }
}

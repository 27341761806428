@import "../../scss/variables";

.logOutPage {
  background-color: $marine-blue;
  height: 100%;
}

.text { padding-top: 70px }

.loginLink {
  font-size: 18px;
  text-align: center;

  a {
    color: $white;
    text-decoration: underline;
  }
}

@media (max-width: 1023px) {
  .text { margin-top: 64px }
}

// Based on https://github.com/theKashey/react-reflexible
.reflexible {
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.reflexibleTry {
  display: inline;
}
.reflexibleFailback {
  display: block;
  overflow: hidden;
  flex-grow: 1;
  width: 0;
  text-overflow: ellipsis;
}

.sortSelector {
  border-radius: 4px;
}

.overflow {
  overflow: visible;
  @media (min-width: 1360px) {
    overflow-y: auto;
  }
}

.spinner {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  right: 50%;
}

.description, .details {
  font-size: 16px;
  font-weight: bold;
}

.description {
  margin: 33.4px 0 8px 0;
}

.details {
  margin: 40px 0 6px 0;
}

.caption {
  opacity: 0.6;
  margin-top: 10px;

  & + div {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
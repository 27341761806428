@import "../../scss/variables";

$context-options-min-width: 150px;

.selectContextTogglebutton {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  min-width: $context-options-min-width;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $main-color;
  font-weight: bold;
  text-align: left;

  .selectedSectionTitle {
    font-weight: normal;
  }
}

.selectContextOptionList {
  position: absolute;
  min-width: $context-options-min-width;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0px 10px 20px 0px $ddl-shadow;
  z-index: 2;
}

.selectContextSection {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.sectionTitle {
  color: $light-grey;
  position: relative;

  div {
    position: relative;
    display: inline-block;
    padding-right: 5px;
    background: $white;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }
}

.selectContextSectionOptions {
  padding: 5px 0;
  list-style: none;
  margin: 0;
}

.selectContextOption {
  font-weight: bold;
  color: $main-color;
  padding: 5px 5px 5px 0;
  border-radius: 5px;

  display: flex;
  align-items: center;

  span {
    padding-left: 5px;
  }

  &.highlighted {
    background: $ddl-item-hover;
  }

  .checkIconWrapper {
    width: 20px;
    height: 20px;
  }
}

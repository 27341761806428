@import "../../scss/variables";

.wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  margin-bottom: 10px;

  .archivedBy {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    color: $marine-blue;
    
    svg {
      fill: $marine-blue;
      margin-right: 8px;
      min-width: 16px;
      max-height: 16px;
    }

    @media (max-width: 1023px) {
      align-items: center;
    }
  }
}

.openEmail {
  padding: 3px 14px;
}

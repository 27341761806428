@import '../../scss/variables.scss';


.container {
  color: $main-color;
  margin-left: 1.7%;

  &:hover .tooltip { display: block }
}

.title {
  height: 44px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: default;
  padding-left: 8px;

  span {
    opacity: 0.6;
    margin-left: 5px;
    text-transform: uppercase;
  }

  svg { margin-left: 14px }
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 2;
  background: $container;
  box-shadow: 0 4px 12px 0 $tooltip-shadow;
  color: $main-color;
  border-radius: 4px;
  width: 190px;
}

.item {
  height: 36px;
  margin: 4px;
  padding-left: 13.7px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $ddl-item-hover;
    border-radius: 4px;
    cursor: pointer;
  }

  .circle {
    width: 19px;
    height: 19px;
    margin-right: 20px;
    border: 2px solid $radiobutton-color;
    border-radius: 50%;
  }

  &.checked .circle {
    border: 2px solid $marine-blue;
    background: radial-gradient(10px 10px at 50% 50%, $marine-blue 35%, transparent 50%);
  }
}

@media (max-width: 1023px) {
  .container {
    width: 120px;
    margin-left: 50px;
  }

  .title {
    height: auto;
    padding-left: 0;
    display: flex;
    justify-content: space-between;

    span { margin-left: 0 }

    svg { display: none }
  }

  .tooltip { right: 8px }
}
@import "../../../scss/variables";

.searchContainer {
  display: flex;
  align-items: center;
}

.inputSearch {
  position: relative;

  input {
    padding: 8px;
    background-color: #f3f4f6;
    border-radius: 4px;
    border: none;

    &::placeholder {
      color: #a3b9cc;
    }

    &:disabled {
      cursor: not-allowed
    }
  }

  svg {
    position: absolute;
    color: #a3b9cc;
    margin: 8px 8px;
    width: 20px;
    height: 20px;
  }


  .clear {
    right: 0;
    cursor: pointer;
  }

  .clear.disabled {
    cursor: not-allowed
  }
}

.searchButton {
  cursor: pointer;
  margin: 8px;
  width: 20px;
  height: 20px;
  color: $button-secondary;
  color: $button-secondary;

  &.disabled {
    cursor: not-allowed
  }
}

.navigationArrow {
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: $button-secondary;
}

.searchResultCount {
  margin: 8px;
}

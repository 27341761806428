@import "../../scss/variables";

.root {
  cursor: pointer;
  dt {
    color: gray;
  }

  dd {
    color: $main-color;
    font-weight: bold;
  }
}

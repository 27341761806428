@import "../../scss/shared.scss";
@import "../../scss/variables";

.wrap {
  background-color: $page-background;
  display: grid;
  align-content: start;
  height: 100%;

  @media (max-width: 1023px) {
    margin-right: 0;
    width: 100%;
    height: fit-content;
  }

  svg {
    min-width: 24px;
    max-height: 24px;
  }
}

.detailsPage {
  height: fit-content;
  min-height: calc(100vh - 82px - 40px); // height - header - back button

  @media (min-width: 1023px){
    height: calc(100vh - 82px - 40px);
  }
}

.scrollable {
  overflow: auto;
  background-color: $white;
  border-radius: 4px;
}

.middleContainer {
  color: $main-color;
  background-color: $white;
  padding: 12px 12px 14px 12px;

  > div:first-child { margin-top: 0 }
  > div { margin-top: 14px }
}

.bottomContainer {
  background-color: $white;
  padding: 0 12px 16px 12px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.senderRecipientRow {
  display: flex;
  justify-content: start;
  align-items: center;

  svg { fill: $marine-blue }
}

.iconAndText {
  display: flex;
  align-items: center;
  color: $main-color;
  margin-right: 8px;

  svg {
    fill: $marine-blue;
    margin-right: 4px;
  }
}

.extraRecipients {
  position: relative;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  text-align: right;

  h6 {
    opacity: 0.6;
  }
}

.extraRecipients:hover .extraRecipientsTooltip { display: flex; }

.extraRecipientsTooltip {
  position: absolute;
  right: 0;
  z-index: 2;
  display: none;
  flex-direction: column;
  cursor: default;
  text-align: left;
  background: $white;
  box-shadow: 0 5px 10px 0 $ddl-shadow;
  border-radius: 4px;
  padding: 18px;

  > ul {
    list-style-type: none;
    padding: 0;

    li:not(:last-child) {
      margin-bottom: 6px;
    }

    li {
      span:first-child { font-weight: bold; white-space: nowrap; }
      span:last-child { font-weight: normal; }

      a {
        display: flex;
        align-items: center;
        color: $main-color;
      }

      a:hover { background-color: $ddl-item-hover; }
    }
  }
}

@mixin nameAndEmail {
  text-align: left;

  &:hover { background-color: $ddl-item-hover }

  a {
    display: grid;
    color: $main-color;
  }

  div { @include ellipsis }
  div:first-child { font-weight: bold }
}

.nameAndEmail {
  @include nameAndEmail;
  margin: 0 16px;
  padding: 0 4px;
  border-radius: 4px;
}

.nameAndEmailExtra {
  @include nameAndEmail;
  padding: 4px 20px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  svg:first-child { margin-right: 20px }
  svg {
    margin-right: 10px;
    fill: $main-color;
  }
}

.attachments {
  display: flex;
  margin-bottom: 14px;

  > svg {
    fill: $marine-blue;
    margin-right: 20px;

    @media (max-width: 1023px) { margin-right: 8px }
  }

  .files {
    flex-grow: 1;
    @media (max-width: 1023px) { overflow: hidden }

    > div { margin-bottom: 8px }
    > div:last-child { margin-bottom: 0 }
  }
}

.line {
  height: 1px;
  background: $dark-blue;
  margin-bottom: 16px;
}

@media (max-width: 1023px) {
  .middleContainer { padding: 12px 8px 14px 8px }

  .bottomContainer { padding: 0 8px 16px 8px }

  .iconAndText > svg { margin-right: 8px }

  .nameAndEmail {
    margin: 0 4px;
    max-width: calc(50% - 32px);

    div { @include ellipsis }
  }

  .extraRecipientsTooltip { right: 6px }
}

.dot {
  min-width: 3px;
  height: 3px;
  background: $main-color;
  border-radius: 50%;
  margin: 3px 4px 0 4px;
}

.error {
  color: $warning;
}

.piiRow {
  display: flex;
  align-items: center;

  .piiSpinner {
    img {
      margin: 0;
      height: 10px;
    }
  }

  .piiIcon {
    // The icon looks a bit weird in terms of alignment to the other icons.
    // A little hack to make it look better visually.
    padding-left: 2px;
  }

  :not(:first-child) {
    margin-left: 20px;

    @media (max-width: 1023px) {
      margin-left: 8px;
    }
  }
}

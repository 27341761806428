@import "../../scss/variables";
@import "../../scss/shared.scss";

.buttons {
  margin-top: 20px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $marine-blue;
  height: 32px;
  font-weight: bold;
}

.closeButton {
  background-color: $marine-blue;
  color: $white;
}

.continueButton {
  background-color: $white;
  color: $marine-blue;
}

.errorFile {
  font-weight: bold;
  margin-bottom: 0px;
}

.documentLink {
  text-decoration: underline;
  cursor: pointer;
}

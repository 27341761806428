@import "../../scss/shared.scss";
@import '../../scss/variables.scss';

.wrap {
    display: flex;
    align-items: center;
    border: solid 1px $light-grey;
    border-radius: 4px;
    cursor: default;
    height: 54px;
    max-width: 56ch;

    > svg {
        cursor: pointer;
        margin-right: 6px;
        fill: $marine-blue;
    }
}

.extension {
    width: 37px;
    height: 100%;
    border-radius: 0;
}

.label {
    flex-grow: 1;
    margin-left: 16px;
    color: $marine-blue;
    overflow-x: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.thumbnail {
    max-width: 41px;
    max-height: 100%;
}

@import "../../scss/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow:auto;
}

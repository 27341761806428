@import "../../scss/variables";

.wrap {
  display: flex;
  align-items: center;

  svg + span {
    margin-left: 5px;
  }
}

.message {
  color: $warning;
  font-size: 16px;
}

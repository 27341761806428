.reactTooltipStyle {
  // Need to use !important to override default - documentet in react-tooltip
  align-items: flex-start !important;
  background: white !important;
  border-radius: 4px !important;
  display: flex !important;
  flex-direction: column !important;
  opacity: 1 !important;
  // box-shadow: 0 4px 12px 0 white !important;

  .tag + .tag {
    margin-left: 0;
    margin-top: 6px;
  }
}

@import "../../scss/variables";

.wrap {
  display: grid;
  grid-template-rows: 35px calc(100% - 35px - 94px) 94px;
  min-width: 440px;
  position: relative;

  @media (max-width: 1023px) { min-width: auto; }
}

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $marine-blue;
    font-size: 21px;
  }

  svg {
    height: 24px;
    width: 24px;
    fill: $marine-blue;
    cursor: pointer;
  }
}

.closeButton {
  text-align: right;
  margin-top: 50px;

  button {
    appearance: none;
    outline: none;
    background-color: $white;
    color: $marine-blue;
    font-weight: bold;
  }
}

@media (max-width: 1023px) {
  .closeButton {
    text-align: center;
    display: flex;

    button {
      flex-grow: 1;
      height: 44px;
      background-color: $marine-blue;
      color: $white;
    }
  }
}
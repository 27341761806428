.loader {
  display: flex;
  justify-content: center;

  img {
    height: 20px;
  }
}

.pageLoader {
  img {
    margin-top: 80px;
  }
}

@import "../../scss/variables";

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: $light-blue-grey;
  padding: 0 8px;

  .goBack {
    display: flex;
    align-items: center;
    color: $marine-blue;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #adc4d8;
    height: 32px;
    // This is a padding hack for the icon in the button - should consider a more reliable solution
    padding-left: 0;
    padding-right: 8px;
  }
}

.grid {
  --action-row-height: 46px;
  --grid-gap: 8px;

  display: grid;
  gap: var(--grid-gap);
  grid-template-areas:
    "leftActions middleActions rightActions"
    "left middle right";
  // Need explicit height so we can use a autosizer on the children
  grid-template-rows:
    var(--action-row-height)
    calc(100% - var(--grid-gap) - var(--action-row-height));
  grid-template-columns: 300px 3fr;
  flex-grow: 1;
  overflow: auto;
  margin: 0 0 0 10px;

  &.hasDetails {
    grid-template-columns: 300px 1fr 1fr;
    margin: 0 10px;
  }

  .leftActions,
  .middleActions,
  .rightActions,
  .left,
  .middle,
  .right {
    &:empty {
      display: none;
    }
  }

  .left,
  .leftActions {
    min-width: 270px;
  }

  .leftActions {
    grid-area: leftActions;
    align-self: end;
    justify-self: start;
  }
  .middleActions {
    grid-area: middleActions;
    display: flex;
    align-items: center;
  }
  .rightActions {
    grid-area: rightActions;
    align-self: end;
    justify-self: end;
  }

  .left {
    grid-area: left;
  }
  .middle {
    grid-area: middle;

    > fieldset {
      height: 100%;
    }
  }
  .right {
    grid-area: right;
  }
}

.sidebarActions {
  display: none;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  width: 350px;
  transform: translateX(-100%);
  animation: slide-in 0.3s forwards;
}

// Custom breakpoint as this depends on how good the view looks with all content visible
// left (filter) - middle (results) - right (details)
@media (max-width: 1700px) {
  .grid {
    grid-template-areas:
      "middleActions rightActions"
      "middle right";
      grid-template-columns: 1fr;

    &.hasDetails {
      grid-template-columns: 2fr 1fr;
    }

    .left,
    .leftActions {
      display: none;
    }
  }

  .sidebarActions {
    display: flex;

    button + button {
      margin-left: 10px;
    }
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

.spinner {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  right: 50%;
}

@import "../../scss/variables.scss";

.logTable {
  width: 100%;
  font-size: 18px;

  th, td {
    padding: 10px;
  }

  th {
    font-weight: bold;
    color: $main-color;
  }

  tr {
    vertical-align: top;
  }

  tbody > tr:nth-child(2n+1) {
    background: $light-blue-grey;
  }

  @media (max-width: 576px) {
    // See: https://css-tricks.com/responsive-data-tables/
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    td { 
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 35%; 
    }
  
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 10px;
      left: 10px;
      padding-right: 10px; 
      white-space: nowrap;
    }
    
    // Label the data
    td:nth-of-type(1):before { content: "Name"; font-weight: bold; }
    td:nth-of-type(2):before { content: "Date"; font-weight: bold; }
    td:nth-of-type(3):before { content: "Time"; font-weight: bold; }
    td:nth-of-type(4):before { content: "Action"; font-weight: bold; }
  }
  
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.changes {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
  max-width: 40ch;

  span {
    font-weight: bold;
  }
}

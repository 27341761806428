@import "../../scss/shared.scss";
@import "../../scss/variables";

@mixin sideColumn {
  width: 4px;
  margin: 2px 0;
  flex: 1;
  border-radius: 2px;
}

@mixin box {
  margin: 5px 10px;
  background-color: $container;
  display: flex;
}

.box {
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 1px #4887b1;
  box-sizing: border-box;
  border-radius: 0.28rem;
  background-color: $container;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.boxDisabled {
  opacity: 0.5;
}

.boxSelected {
  background-color: #cdfaff;
  box-shadow: inset 0px 0px 0px 1px $marine-blue;

  > div > :first-child {
    background-color: $marine-blue;
  }

  > div > :last-child {
    border-color: $marine-blue;
  }

  @media (max-width: 1023px) {
    background-color: #ffffff;

    > div > :last-child {
      border-color: transparent;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: 42px calc(100% - 42px);
  outline: none;
  cursor: default;
  min-height: 5.14rem;
  margin-top: 0.8vh;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: #e8eef3;
  }

  svg {
    cursor: pointer;
    justify-self: center;
    align-self: center;
    height: 18px;
    width: 18px;
    fill: #4887b1;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkboxDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9c9c9c;
}

.sideColumnGreen {
  @include sideColumn;
  background-color: $light-green;
}

.sideColumnBlue {
  @include sideColumn;
  background-color: #46c0da;
}

.sideColumnGrey {
  @include sideColumn;
  background-color: $white;
}

.emptyResults {
  display: flex;
  align-items: center;
  color: $main-color;

  img {
    margin: 0 32px 0 17px;
  }

  div {
    flex: 1;
  }

  .text {
    display: flex;
    font-size: 20px;
    margin-bottom: 5px;

    .bold {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

.topLink {
  @include box;
  background-color: $page-background;
  justify-content: center;
  flex-direction: row;
  color: $main-color;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  cursor: pointer;

  span {
    font-weight: bold;
    margin: 0px 10px;
  }
}

.infiniteScroll {
  height: 72px;
  margin-top: 8px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
  color: $main-color;
  border-radius: 4px;
  cursor: default;
  user-select: none;

  svg {
    margin-right: 8px;
    fill: $main-color;
  }
}

.infiniteScrollClickable {
  cursor: pointer;
}

.indicators {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16px;
  background-color: #4887b1;
  border-radius: 0.28rem 0 0 0.28rem;


  span {
    color: $white;
    font-size: 10px;
    font-weight: bold;
  }
}

.itemContentParts {
  padding: 2px 10px 5px 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100% - 10px - 8px);
}

.itemContent {
  display: flex;
  flex: 1;
}

.itemTopContent {
  display: flex;
  justify-content: space-between;
  color: $date-color;

  & > :first-child > span {
    font-weight: bold;
  }
}

@media (max-width: 1023px) {
  .emptyResults {
    flex-flow: column;
    width: 100%;
    margin-left: 0;

    img {
      margin: 0;
    }

    div {
      margin: 24.7px 16px 0 16px;
    }

    .text {
      flex-flow: column;

      .bold {
        @include ellipsis;
        margin-left: 0;
        width: 248px;
      }
    }
  }

  .subject {
    margin: 2px 0;
    font-size: 12px;
  }
}

.notification {
  border: 1px solid #4887b1;
  color: $marine-blue;
  border-radius: 0.28rem 0.28rem 0 0;
}


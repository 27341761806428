@import "../../../scss/variables";

.wrap {
  position: relative;
  max-width: calc(100vw - 48px);

  .viewerContainer {
    position: absolute;
    max-width: calc(100vw - 48px);
  }
  
  &.modal {
    height: 70vh;
    .viewerContainer {
      height: calc(100% - 60px);
      overflow: auto;
      width: 100%;
    }
  }
}

.searchBarWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 12px 16px;
}

.paging {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  svg {
    cursor: pointer;
    height: 24px;
    width: 24px;
    fill: $marine-blue;
  }

  span {
    margin: 0 10px;
  }
}

@import "../../scss/shared.scss";
@import "../../scss/variables.scss";

.searchWrapper {
  @include searchbar-wrapper;
  background: $main-color;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 4px;
  padding-top: 6px;
  border-radius: 12px;
  flex-shrink: 0;

  // Custom breakpoint for making the search bar look nice with context

  @media (max-width: 1360px) {
    margin: 0;
    width: 100%;
    flex-wrap: initial;
  }
}

.searchBar {
  display: flex;
  width: 100%;
}

.contextWrapper {
  display: flex;
  margin-bottom: 4px;
  min-height: var(--search-field-height);

  button.action {
    color: white;
    &:hover {
      background: transparent;
    }
  }
}

.context {
  flex-grow: 1;
  display: grid;
  gap: 5px;
  padding: 0 5px;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  margin-bottom: 0;

  .item {
    dt {
      color: $vessel-color;
    }

    dd {
      color: white;
    }
  }
}

.back {
  border-radius: 10px 0 0 10px;
  background-color: white;
  width: 48px;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: calc(var(--search-field-height) / 2);
    > circle {
      fill: white;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
}

.additionalActions {
  @include searchbar-wrapper;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  flex-shrink: 0;

  button {
    color: $main-color;
  }
}

.addFilesDialog {
  max-width: 500px;
}

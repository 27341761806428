@import "../../scss/variables";

.title {
  padding-top: 40px;
  padding-bottom: 8px;
  font-size: 28px;
  color: $main-color;
  text-align: left;
  height: 88px;

  @media (max-width: 1023px) {
    padding-top: 8px;
    height: 56px;
  }
}

.wrap {
  display: block;
  svg {
    min-width: 24px;
    max-height: 24px;
  }
}

.content {
  height: calc(100% - 88px - 10px); // Full height - header - margin
  margin: 0 auto;
  max-width: 1300px;
}

@media (max-width: 1023px) {
  .wrap {
    padding: 0;

    > .title { padding: 0 0 0 8px }
  }

  .content {
    height: calc(100% - 56px);
  }
}

@import "../../scss/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  background: $page-background;

  @media (min-width: 1024px) {
    height: calc(100vh - 82px);
    overflow: auto;
    padding-top: 25px;
    padding-bottom: 10px;
  }

  @media (max-width: 1023px) {
    padding: 8px;
    min-height: calc(100vh - 82px)
  }
}

@import "../../scss/variables";

.uploads {
  display: block;
  position: fixed;
  min-width: 410px;
  right: 24px;
  bottom: 24px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 10;
  overflow: auto;

  @media (max-width: 1023px) {
    &.hiddenMobile {
      transform: translateX(92%);
    }
  }

  @media (max-width: 576px) {
    right: 5px;
    left: 5px;
    min-width: unset;

    &.hiddenMobile {
      transform: translateX(87%);
    }
  }
}

.mobileChevron {
  svg {
    transform: rotateZ(135deg);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: $main-color;
  color: white;

  .actions {
    display: flex;
    align-items: center;

    > button {
      color: white;

      &:hover {
        color: $main-color;
      }
    }

    button + button {
      margin-left: 10px;
    }
  }
}

.items {
  margin: 0;
  padding: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 47px; // Need some space for the retry file upload button
  --item-padding: 5px;

  &:not(:first-of-type) {
    padding-top: var(--item-padding);
  }

  &:not(:last-of-type) {
    padding-bottom: var(--item-padding);
    border-bottom: 1px solid $light-blue-grey;
  }

  .filename {
    max-width: 48ch;

    .name {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .filename + div {
    margin-left: 10px;
  }

  &.info {
    font-style: italic;
    color: $light-grey;
  }
}

.uploadError {
  display: flex;
  color: $warning;
  margin-top: 4px;

  svg + div {
    margin-left: 10px;
  }
}

.status {
  padding: 8px;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
}

@import "../../scss/variables";

.container {
  background: $main-color;
  color: $text-light;
}

.actions {
  height: 100%;
}

.userMenu {
  &:hover {
    cursor: default;

    div.userMenuTooltip { display: block }
  }
}

.userIcon { display: none }

.userMenuParts { display: flex }

.userNameRole {
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  text-align: right;
}

.userRole {
  font-weight: bold;
  text-transform: uppercase;
}

.userMenuTooltip {
  display: none;
  position: absolute;
  top: 53px;
  right: 8px;
  width: 200px;
  z-index: 2;
  background: $container;
  color: $main-color;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 $ddl-shadow;

  > div {
    margin: 0 4px;
    padding: 0 13.7px;
    line-height: 36px;
  }
  > :first-child { margin-top: 14px }
  > :last-child { margin-bottom: 14px }
}

.userName {
  font-size: 21px;
  opacity: 0.6;
}

.userMenuItem {
  font-size: 16px;

  &:not(.logout):hover {
    background-color: $ddl-item-hover;
    cursor: pointer;
    border-radius: 4px;
  }
}

.logout {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;

  svg { margin-right: 7px }
}

.logoutButton {
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 1023px) {

  .userIcon { display: block }

  .userMenuParts { display: none }
}

.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;

  > span {
    font-size: 12px;
  }
}

.support,
.support:active,
.support:hover {
  color: white;
}

.remoteUserMenu {
  // Remove inherited styles. (The remote widgets use styled-components)
  a:hover {
    text-decoration: none;
  }
}
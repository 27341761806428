@import "../../scss/variables.scss";
@import "../../scss/shared.scss";

$row-open-color: rgba(78, 195, 224, 0.5);
$row-hover-color: rgba(0, 0, 0, 0.04);
$row-open-hover-color: rgba(78, 195, 224, 0.2);

.tableWrapper {
  background: white;
  border-radius: 4px;
  padding: 8px;
  height: 100%;

  .table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .body {
    height: 100%;
  }

  .tr {
    border-bottom: 1px solid $light-blue-grey;
    &:focus {
      outline: 0;
    }
  }

  .th,
  .td {
    padding: 12px 8px;
  }

  .th {
    color: $light-grey;
    text-transform: uppercase;
    font-weight: normal;
    font-size: $font-size-small;
  }

  .td {
    color: $main-color;

    .truncateOverflow {
      @include truncate-overflow();
    }
  }

  .document {
    @include button-reset;

    &.open {
      background: $row-open-color;
    }

    &:hover {
      background: $row-hover-color;
      cursor: pointer;
    }

    &.open:hover {
      background: $row-open-hover-color;
    }
  }
  .documentList {
    outline: none;
  }
}

.tag {
  min-height: 22px;
}

.tag + .tag {
  margin-left: 6px;
}

.directionWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div:first-of-type {
    flex-grow: 1;
  }

  > div:last-of-type {
    margin-top: 3px;
  }

  .emailIndicator {
    height: 100%;

    // Internal direction has the same color as the background,
    // using box-shadow instead of border to make it look nicer
    &.internalDirection {
      box-shadow: 0px 0px 1px 1px $light-grey;
    }
  }
}

.tooltip {
  // Need to use !important to override default - documentet in react-tooltip
  background: white !important;
  border-radius: 4px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  opacity: 1 !important;
  box-shadow: 0px 10px 20px 0px $ddl-shadow;

  .tag + .tag {
    margin-left: 0;
    margin-top: 6px;
  }
}

.notes,
.title {
  &.limitedSpace {
    max-width: 200px;
  }
}

.keywords {
  flex: 1 1 auto;

  &.limitedSpace {
    max-width: 56px;
  }

  &.limitedSpace[role="columnheader"] {
    font-size: 0;
  }
}

.additionalInfo {
  justify-content: flex-end;
}

.centerVerticalUtil {
  display: flex;
  height: 100%;
  align-items: center;
  [data-reach-custom-checkbox-container] {
    input[type="checkbox"][disabled] + svg {
      color: #9c9c9c;
  }
  }
  [data-reach-custom-checkbox-container]:focus-within,
  [data-reach-custom-checkbox-container][data-focus] {
    box-shadow: none;
    outline: none;
  }
}

.breakWordUtil {
  word-break: break-word;
}

// Claims table needs to be adjusted on many different breakpoints to fit smaller screens
@media (max-width: 1900px) {
  .to,
  .from {
    &.limitedSpace {
      max-width: 100px;
    }
  }

  .notes,
  .title {
    &.limitedSpace {
      max-width: 150px;
    }
  }
}

@media (max-width: 1368px) {
  .notes,
  .title {
    &.limitedSpace {
      max-width: 120px;
    }
  }
}
@import '../../scss/variables.scss';

.menuItemDisabled {
  color: $light-grey;
  cursor: default !important;
}

.menuList {
  position: absolute;
  right: -30px;
  top: 10px;
  width: 200px;
  padding: 9px 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 16px 0 #7F9DB8;
  font-size: 16px;
  color: $marine-blue;
  // Set z-index so it isn't hidden behind the document list scrollbar
  z-index: 2;

  div[role="menuitem"] {
    margin: 2px 5px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $ddl-item-hover;
    }
  }
}

.menuButton {
  background: transparent;

  &:disabled {
    cursor: not-allowed;
  }
}

.dialog {
  @media (min-width: 768px) {
    max-width: 500px;
  }
}

.wideDialog {
  @media (min-width: 768px) {
    max-width: 700px;
  }
}

.title {
  color: $main-color;
  text-align: start;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin truncate-overflow($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

@mixin button-reset {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  text-align: inherit;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  > * {
    height: 100%;
  }
}

@mixin searchbar-wrapper {
  --search-field-height: 44px;
  display: flex;
  min-height: var(--search-field-height);
  width: 70vw;
  max-width:1360px;

  @media (max-width: 1360px) {
    margin: 0 16px;
    width: calc(100% - 32px);

    flex-wrap: wrap;
  }
}

@import "../../../scss/variables";

.scrollWrap {
  padding: 16px 7px 0 7px;
  background: $main-color;
  max-height: 300px;
  border-radius: 4px;
  margin-bottom: 5px;

  @media (max-width: 1023px) {
    border-radius: 0;
  }

  :global(.simplebar-track.simplebar-vertical .simplebar-scrollbar:before) {
    background: white;
    opacity: 0.75;
  }
}

.contexts {
  width: 100%;
  color: white;
  font-weight: bold;

  tbody > tr:hover {
    cursor: pointer;
  }

  tr {
    th {
      color: $vessel-color;
    }

    button {
      padding: 4px;
      &:hover {
        background: $vessel-color;
      }
    }
  }
}

.toggleMore {
  margin-bottom: 5px;
  @media (max-width: 1023px) {
    border-radius: 0;
  }
}

@import "../../scss/variables.scss";
@import "../../scss/shared.scss";


.searchView {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;
  padding-bottom:10px;

  > :first-child {
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}

.errorMessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D9E3EC;
  color: #003C71;
  font-size: 1.6em;
}

@media (max-width: 1023px) {
  .searchView > :first-child {
    padding-bottom: 11px;
  }
}

.searchWrapper {
  @include searchbar-wrapper;
}

.helpButton {
  height: 24px;
  width: 24px;
  fill: $marine-blue;
  align-self: center;
  cursor: pointer;
  margin-left: 5px;
}

.contextSelector {
  > button {
    border-radius: 10px 0px 0px 10px;
  }

  @media (max-width: 576px) {
    flex: 1 0 100%;

    > button {
      height: var(--search-field-height);
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span + button {
    margin-left: 5px;
  }
}

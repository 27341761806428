@import "../../scss/shared.scss";
@import "../../scss/variables";

.wrap {
  background-color: $page-background;
  height: 100vh;
  overflow-y: auto;

  // Remove inherited styles. (The remote widgets use styled-components)
  h1 {
    text-align: unset;
    span { // draft-js "Header 1" block
      color: $marine-blue;
    }
  }
}

.stickyHeader {
  position: sticky;
  top: 0px;
  z-index: 20;
}
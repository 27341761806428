@import "../../scss/variables";

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  color: $marine-blue;

  @media (max-width: 1023px) { margin-left: 24px }

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 17px;
    height: 17px;
    fill: $marine-blue;
  }

  .selectionActions {
    display: flex;

    button + button {
      margin-left: 6px;
    }

    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.info {
  font-size: 18px;
  font-weight: bold;
  width: 250px;
}

.buttonActions {
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:hover { background-color: #B8CADA }
}

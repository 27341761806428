@import "../../scss/variables";
@import "../../scss/shared.scss";

.wrap {
  display: grid;
  grid-template-rows: 35px calc(100% - 35px - 94px) 94px;

  @media (max-width: 1023px) { min-width: auto }
}

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $marine-blue;
    font-size: 21px;
  }

  svg {
    height: 24px;
    width: 24px;
    fill: $marine-blue;
    cursor: pointer;
  }
}

.button {
  width: 100%;
  height: 32px;
  font-weight: bold;
  border-radius: 4px;
}

.closeButton, .moveButton {
  margin-top: 43px;
  color: #FFFFFF;
  background-color: $marine-blue;
}

.cancelButton {
  margin-right: 16px;
  color: $marine-blue;
  background-color: #FFFFFF;
  border: 1px solid $marine-blue;
  margin-top: 10px;
}

.buttons {
  margin-top: 43px;
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 4px;
    height: 32px;
    font-weight: bold;
  }

  > :first-child {
    margin-right: 16px;
    width: 80px;
    color: $marine-blue;
    background-color: #FFFFFF;
    border: 1px solid $marine-blue;
  }

  > :last-child {
    width: 130px;
    color: #FFFFFF;
    background-color: $marine-blue;
  }
}

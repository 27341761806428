@import "../../scss/variables";
@import "../../scss/shared";

.root {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  background-color: $page-background;
}

@mixin card-body-seperator {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: $light-blue-grey;
    bottom: 0;
    left: 20%;
    right: 20%;
  }
}

.card {
  padding: 10px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  border: 1px solid transparent;

  &.selected {
    border-color: $main-color;
  }

  .header,
  .users,
  .content,
  .keywords {
    padding: 10px 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .metadata {
      display: flex;
      align-items: center;
      height: 100%;
      color: $main-color;

      &:first-of-type > :not(:last-child) {
        margin-right: 10px;
      }

      &:last-of-type > :not(:first-child) {
        margin-left: 10px;
      }

      .emailIndicator {
        height: 100%;
      }
    }
  }

  .users {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;

    @include card-body-seperator;
  }

  .content {
    flex-grow: 1;

    @include card-body-seperator;
  }

  .keywords {
    > * {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.truncateOverflow {
  @include truncate-overflow();
}

.document {
  @include button-reset;

  height: 100%;
}

@import "../../scss/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $marine-blue;
  height: 100%;
  background: white;
  padding: 12px;

  .title {
    font-size: 22px;
    font-weight: bold;
    margin: 21px 0 6px 0;
  }

  @media (min-width: 376px) {
    border-radius: 4px;
  }
}

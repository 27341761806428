@import "variables";
@import "../../node_modules/its-react-ui/dist/its-react-ui.css";

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=516a4e0e-7e9a-49d4-9e4d-2a06a1e57306&fontids=6018018,6018024,6018040,6018047,6018049,6053091");

@font-face{
  font-family: "CalibriWeb";
  src: url("../fonts/6018047/50be8057-60b0-4e2a-912a-c4b5832cbfda.woff2") format("woff2"),
       url("../fonts/6018047/c905d629-4a94-4ec9-a6f4-3cd22619dae4.woff") format("woff");
}

@font-face{
  font-family: "CalibriWeb";
  font-weight: bold;
  src: url("../fonts/6018049/843785f0-d584-4c23-b03b-50e9ccdf1236.woff2") format("woff2"),
       url("../fonts/6018049/87748d6a-4116-47a6-bf03-513c883a8872.woff") format("woff");
}

@font-face{
  font-family: "CalibriWeb";
  font-style: italic;
  src: url("../fonts/6018018/a2b7575c-3eee-4beb-84eb-dde180e688d9.woff2") format("woff2"),
       url("../fonts/6018018/aec0feae-b03f-4c08-a33e-c02828c74c0f.woff") format("woff");
}

@font-face{
  font-family: "CalibriWeb";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/6018024/6c249f7f-a62f-4658-a011-0bcfa3dcb59b.woff2") format("woff2"),
       url("../fonts/6018024/546fc141-6f8e-4b37-a6e1-5755beac6f46.woff") format("woff");
}

@font-face{
  font-family: "CalibriWeb Light";
  src: url("../fonts/6053091/1557874f-9258-4fca-9a0d-d491e2ac6b07.woff2") format("woff2"),
       url("../fonts/6053091/28a50974-09bb-4af0-ba4c-aea18344131d.woff") format("woff");
}

@font-face{
  font-family: "CalibriWeb Light";
  font-style: italic;
  src: url("../fonts/6018040/b4ffd83a-2064-4ca8-ab31-dd1cee79d81f.woff2") format("woff2"),
       url("../fonts/6018040/c9c56b1b-a7ea-47f6-ad12-6ab24c4ae41e.woff") format("woff");
}

html,
body {
  background: $main-color; 
  height: 100%;
  font-family: 'CalibriWeb';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: normal;
}

#root { 
  height: 100%; 
  background-color: $page-background;
}

pre { font-family: 'CalibriWeb' }

.height100vh {
  min-height: 100vh;
}

.height100 {
  height: 100%;
}

.paddingTop{
  padding-top: 30px;
}

h1 {
  color: $white;
  text-align: center;
}

h6 {
  font-size: 14px;
}

.container {
  display: grid;
}

.row {
  display: flex;
  flex-direction: row;
}

.iconRow {
  display: flex;
  justify-content: start;
  flex-direction: row;
  margin-top: 10px;
  & svg{
    margin: 0 10px 0 5px;
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1;
}

.flex2 {
  flex: 2;
}

button {
  cursor: pointer;  
	box-shadow: none;
  outline: none; 
  border: 0;   
  &:hover, &:active {
    outline: none;    
  }
}

.fil { 
  color:$main-color;
  border-radius: 2px;
  margin: 2px;
  padding: 2px;
  text-align: center;
  background-color: $light-grey;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.marginLeft {
  margin-left: 5px;
}

.border {
  border-radius: 2.5px 0px 0px 2.5px;
  border: solid 1px $light-grey;
  border-right: 0px;
  margin:0px;
  width: 35px;
}

// override pagination component css

div.pagination div.react-carousel-dots-dot.active {
  background-color: $main-color;
  border-color: $main-color;
}

div.pagination div.react-carousel-dots-dot {
  background-color: $light-blue-grey;
  border-color: $light-blue-grey;
}

div.dot-holder {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

svg {
  display: inline;
}

.btn {
  appearance: none !important;
}

.floatRight {
  float: right;
}

.spaceBetween {  
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center
}

.alignCenter {
  align-items: center;
}

// This will be read by screen readers
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Reach dialog overrides
[data-reach-dialog-overlay] {
  z-index: 10;
}

@media (max-width: 1023px) {
  [data-reach-dialog-content] {
    width: 90vw;
  }
}

// React datepicker overrides
.react-datepicker-popper {
  z-index: 20;
}

fieldset {
  &:disabled {
    filter: opacity(50%) grayscale(1);

    * {
      pointer-events: none;
    }
  }
}
@import "../../scss/variables.scss";

.dialogHeader {
  display: flex;
  justify-content: space-between;

  h1 {
    text-align: left;
    color:$main-color;
  }
}

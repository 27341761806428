@import "../../scss/variables";

.helpWrapper {
  color: $marine-blue;
  font-size: 16px;

  p {
    margin-bottom: 0.5rem;
    width: clamp(36ch, 100%, 90ch);
  }

  section {
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) {
    max-width: 700px;
    font-size: 18px;
  }
}

@import "../../scss/variables";

.root {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 8px;

  svg + span {
    margin-left: 6px;
  }
}

@import "../../scss/variables.scss";

.mobileControls {
  display: flex;
  margin: 0 8px 21px 16px;
  justify-content: space-between;
  align-items: center;

  .clearFilterButton {
    padding: 2px 5px;
    border-radius: 4px;
    background: transparent;
    text-transform: uppercase;
    border: solid 1px $marine-blue;
    color: $marine-blue;
  }

  .sortContainer {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    a {
      color: $main-color;
      font-weight: bold;
      text-decoration: none;
      margin: 0 1.5%;
    }

    .additionalControls {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}

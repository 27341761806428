@import "../../scss/variables";
@import "../../scss/shared.scss";

.wrap {
  display: grid;
  grid-template-rows: auto auto auto;
  min-width: 400px;

  @media (max-width: 1023px) { min-width: auto }
}

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $marine-blue;
    font-size: 21px;
  }
}

.scrollableContent {
  overflow-y: auto;
  height: 400px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  
  input {
    cursor: pointer;
  }

  &[disabled],
  input:disabled  { 
    cursor: not-allowed; 
  }

  span { margin-left: 10px; }
}

.saveButtonWrap {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.saveButton {
  width: 130px;
  height: 32px;
  color: #FFFFFF;
  background-color: #003c71;
  border-radius: 4px;
  font-weight: bold;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.closeButton {
  margin-top: 43px;
  width: 100%;
  border-radius: 4px;
  height: 32px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: $marine-blue;
}

@import "../../scss/variables";

.container {
  background-color: $container;
  border-radius: 4px;
  padding: 10px 12px;
  color: $marine-blue;
  grid-area: filters;
  overflow-y: auto;
  height: 100%;
}

@media (max-width: 1023px) {
  .container {
    width: 100%;
    border-radius: 0px;
    height: unset;
  }
}

// Deep selector due to how simplebar is built.
// Essentialy selects all facets/dates, except for the first
.scrollContainer > div > :nth-child(n + 2) {
  margin-top: 35px;
}

.filterName {
  font-weight: bold;
  margin-bottom: 9px;
}

.dateRange {
  display: flex;
  height: 36px;

  // Overriding style inside DateRangeWrapper from its-react-ui
  > :first-child {
    border-radius: 4px 0 0 4px;
    border-right: none;

    > * {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.datePicker {
  display: flex;

  // Overriding style inside DatePickerWrapper from its-react-ui
  > :first-child {
    flex-grow: 1;
    max-width: 200px;

    input {
      border-radius: 4px 0 0 4px;
      border: 1px solid $marine-blue;
      border-right: none;
      padding: 8px;
    }
  }
}

.searchIcon {
    appearance: none !important;
    width: 48px;
    height: 36px;
    background-color: rgb(0, 60, 113);
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

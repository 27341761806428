@import "../../scss/variables.scss";

.stepActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button + button {
    margin-left: 10px;
  }
}

.tabLabels {
  background: transparent;
  margin-bottom: 40px;
}

.tabLabel {
  color: $main-color;
  border-bottom-width: 3px;
  padding: 0.5em 1em;
  font-size: 16px;
  flex-grow: 1;
  font-weight: bold;

  &[aria-selected="false"] {
    color: gray;
    border-bottom-color: $main-color;
    font-weight: normal;
  }

  &[aria-disabled="true"] {
    border-bottom-color: gray;
  }
}

.submit {
  min-width: 80px;
}

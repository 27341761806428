@import "../../../scss/shared.scss";
@import "../../../scss/variables";

.itemEmailContent {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;

  svg {
    margin: 0 0.5rem;
    min-width: 1.28rem;
  }

  .emailData {
    color: $main-color;
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    justify-content: start;
  }
}

.mobileSubject {
  color: $black-text-color;
  margin-left: 0.5rem;
  font-size: 12px;
  @include ellipsis;
}

.itemBottomLineContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .notes {
    align-items: center;
    color: $main-color;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    flex: 1;
    min-width: 0;

    .notesContent {
      text-align: left;
      @include ellipsis;
    }
  }

  .docTypeTagsAndAttachments {
    display: flex;
    justify-content: space-between;
    flex: 0 0 15rem;

    .docType {
      > span {
        display: flex;
      }
    }

    .tagsAndAttachments {
      display: flex;
      justify-content: flex-end;
      position: relative;
      color: $main-color;

      .tags {
        display: flex;
        justify-content: space-between;
        width: 2.4rem;
        text-align: right;

        > span {
          margin-right: 0.36rem;
        }

        > span:last-child {
          margin-right: 0;
        }
      }

      .attachments {
        margin-left: 0.36rem;
        width: 1.5rem;

        > span {
          display: none;

          &.showAttachments {
            display: block;
          }
        }
      }

      .tooltip {
        background: $container;
        border-radius: 4px;
        box-shadow: 0 4px 12px 0 $tooltip-shadow;
        color: $main-color;
        display: none;
        padding: 0.26rem 0.5rem;
        position: absolute;
        right: 0;
        top: 1.7rem;
        z-index: 2;

        > span {
          display: block;
          margin: 0.36rem 0;
        }

        > span:first-child {
          margin: 0;
        }

        > span:last-child {
          margin: 0.36rem 0 0;
        }

        // must be last
        > span:only-child {
          margin: 0;
        }

        &.showExtensionsTooltip {
          display: block;
        }
      }

      .extension {
        font-size: 0.71rem;
      }
    }
  }
}

@import "../../scss/variables";

.nhcButton {
  border-radius: 4px;
  border: 1px solid $main-color;
  padding: 8px 22px;
  font-weight: bold;

  &.primary {
    color: white;
    background: $main-color;

    &:disabled {
      background: $light-grey;
      border-color: $light-grey;
    }
  }

  &.outline {
    color: $main-color;
    background: white;

    &:disabled {
      color: $light-grey;
      border-color: $light-grey;
    }
  }

  &.light {
    color: black;
    background: $grey-box;
    border-color: $grey-box;
  }

  &.transparent {
    color: $main-color;
    background: transparent;
    border: none;

    &:hover {
      background: #b8cada;
    }
  }

  &.link {
    background: transparent;
    border: none;
    padding: 0;
    color: $main-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

@import "../../scss/variables";

.scrollable {
  overflow-y: auto;
  grid-area: scrollable;
  height: 100%;

  // Add extra space for the scrollbar
  padding-right: 12px;
}

@media (max-width: 1023px) {
  .scrollable {
    height: unset;
    padding-right: 0;

    .searchResults {
      margin: 0 8px;

      > :not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

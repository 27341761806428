@import "../../scss/variables.scss";

.statusPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statusCode {
  color: $main-color;
  font-size: 3rem;
}

.statusMessage {
  font-size: 1.5rem;
}

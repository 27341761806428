@import "../../scss/variables";

.grid {
  display: grid;
  grid-template-rows: auto auto auto auto;
  font-size: 14px;

  @media (max-width: 1023px) {
    width: 100%;
  }
}

.icon {
  width: 64px;
  height: 64px;
  margin-top: 16px;
  justify-self: center;
}

.header {
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
}

.text {
  margin-top: 7px;
  margin-bottom: 24px;
}
